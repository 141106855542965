<template>
    <el-select
        class="project-select-input"
        popper-class="project-select"
        filterable
        clearable
        :disabled="isDisabled"
        v-model="projectId"
        :placeholder="placeholder"
        :filter-method="projectFilter"
        @clear="
            handleProjectChange({
                projectId: '',
                projectName: '',
            })
        "
    >
        <el-option
            v-for="project in projectFilterList"
            :key="project.projectId"
            :value="project.projectId"
            :label="project.projectName"
            :title="project.projectName"
            @click.native="handleProjectChange(project)"
        ></el-option>
    </el-select>
</template>

<script>
import ProjectService from "#/js/service/projectService.js";
export default {
    name: "projectSelect",
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "请输入项目名称",
        },
    },
    data() {
        return {
            projectId: "",
            projectFilterList: [],
            projectList: [],
        };
    },
    mounted() {},
    methods: {
        getProjectList(json) {
            ProjectService.getJobCanBindingProjects({
                customerId: json.customerId,
                customerDepartmentId: json.customerDepartmentId,
            })
                .then((res) => {
                    this.projectList = res;
                    res.forEach((el) => {
                        el.projectName = el.projectName.replace("$#$", "-");
                    });
                    this.projectFilterList = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        projectFilter(val) {
            if (val) {
                this.projectFilterList = this.projectList.filter(
                    (project) =>
                        project.projectName
                            .toLowerCase()
                            .indexOf(val.toLowerCase()) > -1
                );
            } else {
                this.projectFilterList = this.projectList;
            }
        },
        getSelectedProject() {
            return this.projectList.find(
                (item) => item.projectId === this.projectId
            );
        },
        handleProjectChange(project) {
            this.projectId = project.projectId;
            this.$emit("projectSelected", {
                projectId: this.projectId,
                projectName: project.projectName,
            });
        },
        reset() {
            this.projectId = "";
        },
    },
};
</script>

<style lang="scss">
.project-select {
    max-width: 380px;
}
</style>
