import {
    project as projectUrl,
    projectDetail as projectDetailUrl,
} from '#/js/config/api.json';

export default {
    getJobSeriesList() {
        return _request({
            method: "GET",
            baseURL: "LbdOpenApi",
            url: projectUrl.get_job_series_list
        });
    },
    // 获取项目与列表
    getProjectList(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: projectUrl.get_project_list,
            data: params
        });
    },
    // 通过部门ID获取项目列表
    getProjectByDepartment(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: projectUrl.get_project_by_department,
            data: params
        });
    },
    // 创建或编辑项目
    createOrUpdateProject(params) {
        return _request({
            url: projectUrl.create_or_update_project,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },

    // 获取创建项目弹窗的绑定职位列表
    getBindJobList(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: projectDetailUrl.batch_get_project_no_binding_list,
            data: params
        });
    },

    getJobAlreadyBindingProjects(jobId) {
        return _request({
            url: projectUrl.get_job_already_binding_projects.replace(/%p/g, jobId),
            method: "GET",
            baseURL: "LbdOpenApi"
        })
    },
    getJobCanBindingProjects(params) {
        return _request({
            url: projectUrl.get_job_can_binding_projects,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    editProjectJobBindings(params) {
        return _request({
            url: projectUrl.edit_project_job_bindings,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    getProjectTrend(params) {
        return _request({
            url: projectUrl.get_project_trend,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
}
